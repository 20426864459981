


























































































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import UrlType from '@/utils/enums/UrlType';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import MyProfileInputText from '@/components/MyProfileInputText.vue';
import CompanyCmsProfileBaseWidget from '@/components/company-cms/CompanyCmsProfileBaseWidget.vue';
import ActionType from '@/utils/enums/ActionType';
import { ExhibitorUrlFilter } from '@/graphql/_Filters/ExhibitorUrlFilter';
import ExhibitorUrl from '@/models/graphql/ExhibitorUrl';
import SocialUrlWrapper, { Url } from '@/components/wrappers/SocialUrlWrapper';
import ToastActionType from '@/utils/enums/ToastActionType';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import SlotTypeEnum from '@/utils/enums/SlotTypeEnum';
import { Location } from 'vue-router';

const exhibitorUrlStore = namespace('ExhibitorUrlStore');
const salesPackageLimitStore = namespace('SalesPackageLimitStore');

@Component({
  components: {
    MyProfileInputText,
    FontAwesomeComponent,
  },
})
export default class CompanyCmsWebsiteListWidget
  extends mixins(CompanyCmsProfileBaseWidget, SocialUrlWrapper, VueBaseWidget, VueRegisterStoreWidget) {
  @exhibitorUrlStore.Action(ActionType.FILTER)
  private getExhibitorUrls!: (payload: { filter: ExhibitorUrlFilter }) => void;

  @exhibitorUrlStore.Mutation
  private load!: (loading: boolean) => void;

  @exhibitorUrlStore.Action
  private createExhibitorUrl!: (entity: {
    uid: string;
    exhibitorUrl: ExhibitorUrl;
  }) => Promise<ExhibitorUrl | undefined>;

  @exhibitorUrlStore.Action
  private updateExhibitorUrl!: (exhibitorUrl: ExhibitorUrl) => void;

  @exhibitorUrlStore.Action
  private deleteExhibitorUrl!: (uid: string) => void;

  @exhibitorUrlStore.Getter
  private fetchExhibitorUrls!: ExhibitorUrl[];

  @salesPackageLimitStore.Action
  private filterSlots!: (payload: { exhibitorUid: string; code: string }) => Promise<{ type: string; limit: number }[]>;

  private urlType = UrlType;

  private defaultWebsites: ExhibitorUrl = {
    uid: '',
    type: '',
    name: '',
    url: '',
    deleted: false,
  };

  private limit = -1;

  private totalCount = 0;

  private entityCode = '';

  private get exhibitorWebsites(): ExhibitorUrl[] {
    const socials = this.fetchExhibitorUrls?.filter((url) => {
      const type = url.type?.toLowerCase();
      return (
        (type === UrlType.SECONDARY_WEBSITE)
      );
    }) as ExhibitorUrl[];
    return socials?.length > 0 ? socials : [this.defaultWebsites];
  }

  private get canAddUrl(): boolean {
    if (this.limit !== -1) {
      return (this.limit - this.totalCount) > 0;
    }
    return true;
  }

  created(): void {
    this.setDataConfig();
    if (this.payload
          && 'entityCode' in this.payload
          && this.payload.entityCode) {
      this.entityCode = WidgetHelper
        .entityCode(this.payload.entityCode as string, this.$route as unknown as Location);
      this.getCompanyContactInformation();
    }
    if (this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE).enabled) {
      this.filterSlots({
        exhibitorUid: this.entityCode,
        code: SlotTypeEnum.URL_SLOT,
      }).then((response) => {
        if (response && response[0]) {
          this.limit = response[0].limit;
        }
      });
    }
  }

  getCompanyContactInformation(): void {
    if (this.entityCode) {
      this.getExhibitorUrls({
        filter: {
          exhibitor: {
            uid: this.entityCode,
          },
        },
      });
    }
  }

  @Watch('fetchExhibitorUrls', { immediate: true })
  getAllExhibitorUrls(): void {
    this.userContacts.secondaryWebsiteUrls = this.exhibitorWebsites;
    this.totalCount = this.exhibitorWebsites.length ?? 0;
  }

  private onUrlChange(url: ExhibitorUrl, urlType: UrlType, urlLocalIndex: number): void {
    this.onUrlInputChange({ ...url, type: urlType }, urlLocalIndex,
      {
        userUrls: this.fetchExhibitorUrls,
        createUserUrl: this.addUrl,
        updateUserUrl: this.updateExhibitorUrl,
      },
      {
        onRemoveUrlClick: this.onRemoveUrlClick,
        showToast: this.showToast,
      });
  }

  private addUrl(url: ExhibitorUrl): void {
    const exhibitorUrl: ExhibitorUrl = {
      uid: '',
      name: url.name,
      type: url.type,
      url: url.url,
    };

    this.load(true);
    this.createExhibitorUrl({ uid: this.entityCode, exhibitorUrl })
      .then(() => {
        this.load(false);
        this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
      });
  }

  private onAddUrlClick(): void {
    const exhibitorUrl = {
      uid: '',
      name: '',
      type: '',
      url: '',
    };
    (this.userContacts.secondaryWebsiteUrls as Url[]).push(exhibitorUrl);
    this.totalCount += 1;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onRemoveUrlClick(uid: string, urlType: UrlType, urlIndexLocal: number): void {
    if (uid) {
      this.deleteExhibitorUrl(uid);
      this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
    }

    if (!this.userContacts.secondaryWebsiteUrls.length) {
      return;
    }
    this.userContacts.secondaryWebsiteUrls.splice(urlIndexLocal, 1);
    if (this.userContacts.secondaryWebsiteUrls.length === 0) {
      this.userContacts.secondaryWebsiteUrls.push(this.defaultWebsites);
    }
    this.totalCount -= 1;
  }

  private initDataNotSaved(): void {
    this.dataNotSaved = true;
  }
}
