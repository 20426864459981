var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay && _vm.limit !== 0)?_c('div',{class:['company-cms-website-list-widget container',
           _vm.breakpoint.value, _vm.classList, _vm.isMobile]},[_c('div',{class:['card d-flex flex-column row-gap-6', _vm.breakpoint.value]},[_c('h4',{staticClass:"text-left m-0"},[_vm._v(" "+_vm._s(_vm.$t('app.admin-panel.profile-tab.other-websites'))+" ")]),_c('div',{staticClass:"d-flex flex-column row-gap-3"},_vm._l((_vm.userContacts.secondaryWebsiteUrls),function(social,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('div',{class:[
            'w-100 d-flex',
            {'flex-column': _vm.isMobile}
          ]},[_c('div',{class:[
              {'flex-grow-3': _vm.isDesktop},
              {'flex-grow-1': _vm.isTablet},
              {'mb-2 d-flex': _vm.isMobile},
              {'mr-2': !_vm.isMobile}
            ]},[_c('my-profile-input-text',{class:[{'w-100': _vm.isMobile}],attrs:{"default-value":social.name,"label":("" + (_vm.$t('app.admin-panel.profile-tab.name'))),"self-context":_vm.currentSelfContext === ("secondary-website-" + index)
                ? 'ExhibitorUrlStore' : ''},on:{"update:defaultValue":function($event){return _vm.$set(social, "name", $event)},"update:default-value":function($event){return _vm.$set(social, "name", $event)},"blur":function($event){return _vm.onUrlChange(social, _vm.urlType.SECONDARY_WEBSITE, index)},"input":function($event){_vm.initDataNotSaved(); _vm.inputChanged = true;}}}),(_vm.isMobile)?_c('div',{staticClass:"d-flex column-gap-2 ml-2 pt-2 align-items-start add-remove-buttons-sm"},[(_vm.canAddUrl)?_c('div',{staticClass:"mr-1",on:{"click":function($event){return _vm.onAddUrlClick()}}},[_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-solid fa-circle-plus","icon-classes":"text-blue-b-3-primary-blue"}})],1):_vm._e(),_c('div',{class:{'disabled-btn': _vm.userContacts.secondaryWebsiteUrls.length <= 1},on:{"click":function($event){return _vm.onRemoveUrlClick(social.uid, _vm.urlType.SECONDARY_WEBSITE, index)}}},[_c('font-awesome-component',{attrs:{"icon-classes":[_vm.userContacts.secondaryWebsiteUrls.length > 1 ?
                    'text-red-r-3-primary-red' : 'text-neutral-n-3-disable'],"container-classes":"icon-24","icon":"fa-solid fa-circle-minus"}})],1)]):_vm._e()],1),_c('div',{staticClass:"flex-grow-1",class:[
              {'flex-grow-1': _vm.isDesktop || _vm.isTablet},
              {'w-100': _vm.isMobile} ]},[_c('my-profile-input-text',{attrs:{"default-value":social.url,"label":("" + (_vm.$t('app.admin-panel.profile-tab.website'))),"self-context":_vm.currentSelfContext === ("secondary-website-" + index)
                ? 'ExhibitorUrlStore' : '',"validation":"website"},on:{"update:defaultValue":function($event){return _vm.$set(social, "url", $event)},"update:default-value":function($event){return _vm.$set(social, "url", $event)},"blur":function($event){return _vm.onUrlChange(social, _vm.urlType.SECONDARY_WEBSITE, index)},"input":function($event){_vm.initDataNotSaved(); _vm.inputChanged = true;}}})],1)]),(!_vm.isMobile)?_c('div',{class:[
            'd-flex column-gap-2 ml-2 pt-2',
            {'align-items-start add-remove-buttons-sm': _vm.isMobile},
            {'mb-2 align-items-end': !_vm.isMobile} ]},[(_vm.canAddUrl)?_c('div',{staticClass:"mr-1",on:{"click":function($event){return _vm.onAddUrlClick()}}},[_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-solid fa-circle-plus","icon-classes":"text-blue-b-3-primary-blue"}})],1):_vm._e(),_c('div',{class:{'disabled-btn': _vm.userContacts.secondaryWebsiteUrls.length <= 1},on:{"click":function($event){return _vm.onRemoveUrlClick(social.uid, _vm.urlType.SECONDARY_WEBSITE, index)}}},[_c('font-awesome-component',{attrs:{"icon-classes":[_vm.userContacts.secondaryWebsiteUrls.length > 1 ?
                'text-red-r-3-primary-red' : 'text-neutral-n-3-disable'],"container-classes":"icon-24","icon":"fa-solid fa-circle-minus"}})],1)]):_vm._e()])}),0)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }